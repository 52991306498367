import React from "react";
import './ServiceCard.css';
import { useNavigate } from "react-router-dom";

export const ServiceCard = ({ service }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/nos-services/${service.id}`);
        window.scrollTo(0, 0)
    };

    return (
        <div
            onClick={handleClick}
            className="carousel-item relative max-w-md space-x-4"
            role="button"
            aria-label={`Voir les détails pour ${service.title}`}
        >
            <div className="card bg-base-100 image-full border-gray-200 cursor-pointer">
                <figure>
                    <img
                        src={service.poster}
                        alt={service.title}
                        className="object-cover w-full h-full"
                    />
                </figure>
                <div className="card-body text-left">
                    <h3 className="card-title text-2xl absolute inset-0 left-8 top-32 text-white">{service.title}</h3>
                </div>

            </div>
        </div >
    )
}