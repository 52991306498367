import React, { useState } from 'react';

export const GaleryMansionGrid = ({ image }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const closeModal = () => {
        setSelectedImage(null);
        document.getElementById('image_modal').close();
    };

    const handleImageClick = () => {
        setSelectedImage(image);
        document.getElementById('image_modal').showModal();
    };

    const handleClickOutside = (e) => {
        const modalBox = document.querySelector('.modal-box');
        if (modalBox && !modalBox.contains(e.target)) {
            closeModal();
        }
    };

    return (
        <div>
            <div>
                <img
                    className="h-auto max-w-full rounded-lg object-cover object-center cursor-pointer"
                    src={image}
                    onClick={handleImageClick}
                    loading="lazy"
                />
            </div>
            <dialog id="image_modal" className="modal" onClick={handleClickOutside}>
                <div className="modal-box">
                    <button
                        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                        onClick={closeModal}
                    >
                        ✕
                    </button>

                    {selectedImage && (
                        <div className="py-4">
                            <img
                                src={selectedImage}
                                alt="Zoomed"
                                className="w-full h-auto rounded-lg object-cover object-center cursor-pointer"
                            />
                        </div>
                    )}
                </div>
            </dialog>
        </div>
    );
};
