import { FormContact } from "./components/FormContact"
import React from "react";

export const Contact = () => {
    return (
        <section className="mb-3" id="contact">
            <main>
                <div>
                    <h2 className="text-4xl font-bold text-left mx-3 py-8">Contactez-nous</h2>
                    <p className="text-lg text-left mb-3 ml-2">Devis et déplacement gratuit, n'hésitez pas à faire appel à nos services, nous intervenons dans tous le pays de Montbéliard (25) et ses alentours, ainsi que le territoire de Belfort (90) et ses alentours.</p>
                </div>
                <div className="flex flex-col xl:flex-row justify-center items-center gap-5">
                    <div className="flex flex-col items-center">
                        <div>
                            <p className="font-medium text-xl mb-3">Du lundi au samedi de 9h00 à 18h00.</p>
                            <p className="text-xl font-bold mb-5">Par téléphone :</p>
                            <div className='flex flex-col w-96'>
                                <button className="btn btn-primary rounded-full mb-3 text-2xl">
                                    Chantier
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                                    </svg>
                                    <a href="tel:+33659308438">06 59 30 84 38</a>
                                </button>
                                <button className="btn btn-primary rounded-full mb-3 text-2xl">
                                    Bureau
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                        <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                                    </svg>
                                    <a href="tel:+33381977254">03 81 97 72 54</a>
                                </button>
                            </div>
                        </div>
                        <div>
                            <p className="text-xl font-bold mb-3">Par email :</p>
                            <button className="btn btn-secondary rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                                </svg>
                                <a href="mailto:mwpierreespacesverts@gmail.com" className="text-xl">mwpierreespacesverts@gmail.com</a>
                            </button>
                        </div>
                    </div>
                    <div className="divider lg:divider-horizontal">OU</div>
                    <div className="flex flex-col w-96">
                        <FormContact />
                    </div>
                </div>
            </main>
        </section>
    )
}