import React from "react";
import { Services } from "../Services/Services";
import { Hero } from './components/Hero';
import { Maintenance } from '../Maintenance';
import { Carousel } from "./components/Carousel";
import { Contact } from "../Contact/Contact";
import { Videos } from "./components/Videos";


export const Home = () => {

    return (
        <div>
            <Hero />
            <main>
                <Services />
                <Contact />
                <Videos />
            </main>
        </div>
    )

}

