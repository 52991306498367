import React, { useEffect, useState } from "react";
import { getServicesList } from "../../services/dataService";
import { ServiceCard } from "../../components/Elements/ServiceCard";
import { AllTags } from "./components/AllTags";
import { useTitle } from '../../hook/useTitle';

export const Services = () => {

    const [services, setServices] = useState([]);
    const [uniqueTags, setUniqueTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useTitle("Découvrez nos services");

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await getServicesList();
                setServices(data);
                setUniqueTags(getUniqueTags(data));
            } catch (err) {
                setError("une erreur est survenue lors de la récup des services");
            } finally {
                setLoading(false);
            }
        };
        fetchServices();
    }, [])

    const getUniqueTags = (services) => {
        const allTags = services.flatMap(service => service.tags || []);
        return [...new Set(allTags)];
    };

    if (loading) {
        return (
            <span className="loading loading-spinner text-secondary"></span>
        )
    }

    if (error) {
        return (
            <div>{error}</div>
        )
    }

    if (services.length === 0) {
        return <div>Aucun service disponible</div>;
    }

    return (
        <section id="services" className="services">
            <h2 className="font-bold text-4xl mx-3 py-8 text-left">Nos prestations</h2>
            <article className="mb-3">
                <div className="carousel w-full gap-2">
                    {services.map(service => (
                        <ServiceCard key={service.id} service={service} />
                    ))}
                </div>
            </article>
            <article className="flex flex-wrap gap-2 py-8 justify-center">
                <button className="btn btn-primary rounded-full no-animation">TOUS</button>
                {uniqueTags.map((tag, index) => (
                    <AllTags key={index} tag={tag} />
                ))}
            </article>
        </section>
    )
}
